
import Vue from "vue";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
	IIngredient,
	IIngredientAllergen,
	IIngredientDataReq,
	IIngredientRES,
	EMPTY_INGREDIENT,
	IIngredientCategory
} from "@common/ingredient";
import { IProductionStandardTime, EMPTY_PRODUCTION_STANDARD_TIME } from "@common/productionStandardTime";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import router from "@/router";

export default Vue.extend({
	name: "AddIngredient",
	data: () => ({
		process: false as boolean,
		ingredient: EMPTY_INGREDIENT as IIngredient,
		productionStandardTime: EMPTY_PRODUCTION_STANDARD_TIME as IProductionStandardTime,
		ingredientAllergens: [] as IIngredientAllergen[],
		pdfTechnicalSheet: {
			data: "",
			delete: false
		},
		pdfInfoPoster: {
			data: "",
			delete: false
		},
		img: {
			data: "",
			delete: false
		},
		video: {
			data: "",
			delete: false
		},
		ingredientCategories: [] as IIngredientCategory[]
	}),
	created: async function () {
		this.store.dispatch.changeAppTitle("Add new ingredient");

		if (this.store.getters.allergens.length === 0) {
			await this.store.dispatch.fetchAllergens();
		}

		if (this.store.getters.ingredientCategories.length === 0) {
			await this.store.dispatch.fetchIngredientCategories();
		}

		if (this.store.getters.productionStandardTimes.length === 0) {
			await this.store.dispatch.fetchProductionStandardTimes();
		}

		this.ingredientCategories = JSON.parse(JSON.stringify(this.store.getters.ingredientCategories));
		this.ingredientCategories.map((category) => {
			category.name = `(${category.id}) ${category.name}`;

			return category;
		});

		this.ingredient = { ...EMPTY_INGREDIENT };
		this.ingredient.unit = this.store.state.ingredientsInfos.units[0];
		this.ingredient.storageCondition = this.store.state.ingredientsInfos.storageConditions[0];
		this.ingredient.category = this.store.getters.ingredientCategories[0].id;

		for (const allergen of this.store.getters.allergens) {
			this.ingredientAllergens.push({
				id: "",
				ingredientId: this.ingredient.id,
				allergen: { ...allergen }
			});
		}
	},
	methods: {
		async saveIngredient () {
			this.process = true;
			try {
				if (this.ingredient.fixedWeight?.toString() === "") {
					this.ingredient.fixedWeight = null;
				}

				const data: IIngredientDataReq = {
					ingredient: this.ingredient,
					ingredientAllergens: this.ingredientAllergens,
					imgHash: this.img,
					videoHash: this.video,
					pdfTechnicalSheet: this.pdfTechnicalSheet,
					pdfInfoPoster: this.pdfInfoPoster
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/ingredient/add`,
				};
				const res: AxiosResponse<IServerRES<IIngredientRES>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					if (res.data.payload.succeeded) {
						this.store.dispatch.addIngredient(res.data.payload.ingredient);
					}
				} else {
					// TODO ERROR DISPLAY
					console.error(new Error(res.data.payload.message));
				}
			} catch (err) {
				// TODO ERROR DISPLAY
				console.error(err);
			}
			try {
				const data: IProductionStandardTime = {
					productId: this.ingredient.id,
					id: this.ingredient.id,
					minimumQuantity: this.productionStandardTime.minimumQuantity,
					fixedValue: this.productionStandardTime.fixedValue,
					variableValue: this.productionStandardTime.variableValue,
					miseEnPlace: 0
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/production-standard-time/add`,
				};
				const res: AxiosResponse<IServerRES<IProductionStandardTime>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					const newProductionStandardTime = res.data.payload;
					this.store.dispatch.addProductionStandardTime(newProductionStandardTime);
				} else {
					console.error(new Error(res.data.payload.message));
				}
			} catch (err) {
				console.error(err);
			}

			router.push({ path: "/ingredients" });
		},
		fileChange (e: Event) {
			const target = e.target as HTMLInputElement;

			if (target.files !== null &&
							target.files.length) {
				switch (target.id) {
					case ("pdf_technical_sheet_file"):
						if (target.files[0]) {
							const pdfReader: FileReader = new FileReader();
							pdfReader.readAsDataURL(target.files[0]);
							pdfReader.onload = async () => {
								const result: string|undefined = pdfReader.result?.toString().split(",")[1];
								if (result) {
									this.pdfTechnicalSheet.data = result;
								}
							};
						}
						break;
					case ("pdf_info_poster_file"):
						if (target.files[0]) {
							const pdfReader: FileReader = new FileReader();
							pdfReader.readAsDataURL(target.files[0]);
							pdfReader.onload = async () => {
								const result: string|undefined = pdfReader.result?.toString().split(",")[1];
								if (result) {
									this.pdfInfoPoster.data = result;
								}
							};
						}
						break;
					case ("img_file"):
						if (target.files[0]) {
							const imgReader: FileReader = new FileReader();
							imgReader.readAsDataURL(target.files[0]);
							imgReader.onload = async () => {
								const result: string|undefined = imgReader.result?.toString().split(",")[1];
								if (result) {
									this.img.data = result;
								}
							};
						}
						break;
					case ("video_file"):
						if (target.files[0]) {
							const videoReader: FileReader = new FileReader();
							videoReader.readAsDataURL(target.files[0]);
							videoReader.onload = async () => {
								const result: string|undefined = videoReader.result?.toString().split(",")[1];
								if (result) {
									this.video.data = result;
								}
							};
						}
						break;
				}
			} else if (target.files !== null &&
							target.files.length === 0) {
				switch (target.id) {
					case ("pdf_technical_sheet_file"):
						this.pdfTechnicalSheet.data = "";
						break;
					case ("pdf_info_poster_file"):
						this.pdfTechnicalSheet.data = "";
						break;
					case ("img_file"):
						this.img.data = "";
						break;
					case ("video_file"):
						this.video.data = "";
						break;
				}
			}
		}
	},
});
